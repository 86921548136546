<template>
  <v-row class="my-5">
    <div class="main-bg-img"></div>
    <v-col cols="12" sm="10" md="8" lg="6" class="mx-auto text-center">
      <v-card v-if="commande != null">
        <v-col cols="12">
          
          <v-card-title class="green--text">
            <v-icon x-large color="green">mdi-thumb-up</v-icon>
            <br>
            <template v-if="commande.status == 'PREPAID'">Paiement de l'acompte validé</template>
            <template v-if="commande.status == 'PAID'">Paiement final validé</template>
          </v-card-title>

          <v-divider/>

          <br>
          <div style="padding: 10px 50px;">
            <v-card-text class="font-flower text-left" style="font-size:23px;line-height:30px;">
              <template v-if="commande.status == 'PAID'">
                Merci pour votre paiement <span style="text-transform:capitalize">{{ commande.name }}</span>.
                <br>Je m'occupe personnellement de vous faire parvenir votre mandala au plus vite !
              </template>
              
              <template v-if="commande.status == 'PREPAID'">
                Merci pour votre paiement <span style="text-transform:capitalize">{{ commande.name }}</span>.
                <br><br>
                Votre commande est maintenant entièrement validée, je m'en occupe dès que possible ! 
                Vous recevrez un e-mail dès que j'aurais terminé.
                <br><br>
                A très bientôt !
              </template>
            </v-card-text>
            <v-card-text class="font-flower text-right" style="font-size:33px;">
              Nanoug
            </v-card-text>
          </div>
          <br>

          <v-divider/>

          <br>
          <v-toolbar color="transparent" elevation="0">
            <v-spacer/>
          <v-btn class="my-2 mx-auto" large color="blue" dark to="/shop">
            <b>Retourner vers la boutique</b>
            <v-icon class="mr-1">mdi-arrow-right</v-icon> 
          </v-btn>
          </v-toolbar>

        </v-col>
      </v-card>
    </v-col>
  </v-row>
</template>

<script>
import axios from "axios"

export default {
  name: "App",
  components: {
  },
  data: function () {
      return {
        commande: null
      };
  },
  mounted: async function(){
    await this.reloadEntity()
    this.afterPayement()

  },
  methods: {

      async afterPayement(){
        console.log("afterPayement", this.commande.totalPaid)
        let action = this.commande.totalPaid > 0 ? "after-payment-all" : "after-payment-accompte"

        console.log('Call /commande/'+action+'/' + this.$route.params.commandeId)
        const { data } = await axios.post('/commande/'+action+'/' + this.$route.params.commandeId)
        if(data.error == false){
          this.$store.dispatch('app/openDialogMailDev', data.emailParams)
          this.reloadEntity()
        }else{
          console.log('Error after /commande/'+action+'/' + this.$route.params.commandeId)
          this.formError = true
          //router.push("/thanks/"+this.$route.params.commandeId)
        }
      },
      async reloadEntity(){
        let res = await axios.post('/data/query-entities', {
                          entityType: "commande",
                          query: { _id: this.$route.params.commandeId  }
                        })
        if(res.data.error == false){
          this.commande = res.data.entities[0]
        }
      }

  },
  computed:{
    
  }
};
</script>
